<template>
    <nl-loader v-if="preloading" :show="true" />
    <div v-else>
        <b-collapse class="card show mb-3" animation="slide" aria-id="contentIdForA11y3" :open="headerIsOpen">
            <template #trigger="props">
                <header
                    class="card-header align-items-center p-1"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open"
                >
                    <h2 class="m-0 card-header-title has-text-weight-bold">
                        {{ site.ndd }}
                    </h2>
                    <b-field grouped group-multiline class="m-0">
                        <div class="control">
                            <b-taglist attached>
                                <b-tag type="is-warning" size="is-small"> MC SEObserver</b-tag>
                                <b-tag type="is-dark" size="is-small">
                                    {{ site.nb_mots_cles_seobserver ? site.nb_mots_cles_seobserver : '-' }}
                                </b-tag>
                            </b-taglist>
                        </div>
                        <div class="control">
                            <b-taglist attached>
                                <b-tag type="is-success" size="is-small"> MC NextLevel</b-tag>
                                <b-tag type="is-dark" size="is-small">
                                    {{ site.nbKeywords ? site.nbKeywords : '-' }}
                                </b-tag>
                            </b-taglist>
                        </div>
                    </b-field>
                    <div class="card-header-icon">
                        <b-icon size="is-medium" :icon="props.open ? 'caret-down' : 'caret-up'" />
                    </div>
                </header>
            </template>

            <div class="card-content w-100">
                <div class="d-flex flex-wrap" style="gap: 0.8rem">
                    <AllowedOutgoingDomainsModal :site="site" @saved="forceUpdate = !forceUpdate" />

                    <BlackWhiteListModal :site="site" @editBlackWhiteList="editBlackWhiteList" />
                </div>
                <hr />
                <div class="d-flex flex-column">
                    <div class="form-wrapper">
                        <b-field class="span-lg">
                            <template #label>
                                <div class="d-flex justify-content-between w-100" style="gap: 0.5rem">
                                    Commission
                                    <b-tooltip label="Correspond à la part revenant au partenaire lors d'un achat">
                                        <sup>
                                            <b-icon icon="info-circle" type="is-info" />
                                        </sup>
                                    </b-tooltip>
                                </div>
                            </template>
                            <b-numberinput
                                v-model="formData.commission"
                                expanded
                                min="50"
                                max="100"
                                step="5"
                                controls-position="compact"
                            />
                        </b-field>
                        <b-field class="span-lg">
                            <template #label>
                                <div class="d-flex justify-content-between w-100" style="gap: 0.5rem">
                                    Gain minimum global souhaité
                                    <b-tooltip label="Correspond au gain minimum souhaité sur les URLs du site">
                                        <sup>
                                            <b-icon icon="info-circle" type="is-info" size="is-small" />
                                        </sup>
                                    </b-tooltip>
                                </div>
                            </template>
                            <b-numberinput
                                v-model="formData.prix_minimum_filtre"
                                expanded
                                :step="5"
                                :min="0"
                                controls-position="compact"
                            />
                        </b-field>
                        <b-field class="span-lg">
                            <template #label>
                                <div class="d-flex justify-content-between w-100" style="gap: 0.5rem">
                                    Prix client minimum à la vente
                                    <b-tooltip label="Correspond au prix client minimum à la vente">
                                        <sup>
                                            <b-icon icon="info-circle" type="is-info" size="is-small" />
                                        </sup>
                                    </b-tooltip>
                                </div>
                            </template>
                            <b-numberinput
                                v-model="formData.prix_minimum_base"
                                expanded
                                step="5"
                                min="0"
                                controls-position="compact"
                            />
                        </b-field>
                        <b-field label="xPath" class="span-xl">
                            <b-input v-model="formData.xpath_field" placeholder="xPath" />
                        </b-field>
                        <b-field :label="$t('global.category')" class="span-xl">
                            <b-select v-model="formData.group_id" :disabled="priceUpdatePending">
                                <option v-for="group in groups" :key="group.id" :value="group.id">
                                    {{ group.localized_translations.name }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Type">
                            <b-select v-model="formData.site_type" :disabled="priceUpdatePending">
                                <option value="or">Or</option>
                                <option value="argent">Argent</option>
                                <option value="bronze">Bronze</option>
                                <option value="vipor">VIP Or</option>
                                <option value="vipargent">VIP Argent</option>
                                <option value="vipbronze">VIP Bronze</option>
                                <option value="media">Media</option>
                            </b-select>
                        </b-field>
                        <b-field label="Ignorer les liens sortants" class="span-md">
                            <b-switch
                                v-model="formData.outrepasser_liens"
                                :true-value="1"
                                :false-value="0"
                                type="is-success"
                            >
                                {{ formData.outrepasser_liens ? $t('global.yes') : $t('global.no') }}
                            </b-switch>
                        </b-field>
                        <b-field label="Refus MFA concurrent" class="span-md">
                            <b-switch
                                v-model="formData.refus_concurrent_mfa"
                                true-value="oui"
                                false-value="non"
                                type="is-success"
                            >
                                {{ formData.refus_concurrent_mfa ? $t('global.yes') : $t('global.no') }}
                            </b-switch>
                        </b-field>
                    </div>
                </div>
                <hr />
                <div class="d-flex justify-content-center justify-content-lg-end">
                    <b-button
                        :label="$t('global.save')"
                        icon-left="save"
                        type="is-success"
                        :disabled="!canSubmitForm"
                        @click="submitForm"
                    />
                </div>
            </div>
        </b-collapse>
        <b-message
            v-if="scrapPending || serpPending || priceUpdatePending"
            type="is-info is-light"
            has-icon
            icon="info-circle"
        >
            <div class="d-flex flex-column">
                <div v-if="scrapPending" class="d-flex" style="gap: 0.5rem">
                    <b-icon icon="code" />
                    Le scrap est en cours
                    <b-icon icon="circle-notch fa-spin" />
                </div>
                <div v-if="serpPending" class="d-flex" style="gap: 0.5rem">
                    <b-icon icon="download" />
                    Le récupération des mots clés seobserver est en cours
                    <b-icon icon="circle-notch fa-spin" />
                </div>
                <div v-if="priceUpdatePending" class="d-flex" style="gap: 0.5rem">
                    <b-icon icon="euro-sign" />
                    Le recalcule du prix est en cours
                    <b-icon icon="circle-notch fa-spin" />
                </div>
            </div>
        </b-message>
        <div class="box">
            <NlTabs :tabs="tabs">
                <template #default="{ openOnce, tab: { type, index } }">
                    <UrlTable
                        v-if="openOnce[index]"
                        :force-update.sync="forceUpdate"
                        :price-update-pending="priceUpdatePending"
                        :scrap-pending="scrapPending"
                        :serp-pending="serpPending"
                        :status="type"
                        type="specific"
                        @reindex="handleReindex"
                        @scrap="handleScrap"
                        @scrapUrl="handleScrap"
                        @serp="handleSerp"
                    />
                </template>
            </NlTabs>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import AllowedOutgoingDomainsModal from '@/components/Admin/Sites/Urls/AllowedOutgoingDomainsModal.vue'
    import NlTabs from '@/components/UI/Tabs/NlTabs.vue'
    import UrlTable from '@/components/Admin/Sites/Urls/UrlTable.vue'
    import BlackWhiteListModal from '@/components/Admin/Sites/Urls/BlackWhiteListModal.vue'

    export default {
        name: 'SiteUrls',
        components: {
            NlTabs,
            UrlTable,
            AllowedOutgoingDomainsModal,
            BlackWhiteListModal
        },
        data() {
            return {
                counts: {
                    total: 0,
                    saleable: 0,
                    not_saleable: 0,
                    not_scrapped: 0
                },
                forceUpdate: false,
                formData: {},
                formLoading: false,
                groups: [],
                headerIsOpen: true,
                preloading: true,
                site: null,
                timer: 0,

                // Pending tasks
                priceUpdatePending: true,
                scrapPending: true,
                serpPending: true,

                updateActive: {
                    authorized_outgoing_domain: false,
                    xpath_field: false
                }
            }
        },
        computed: {
            canSubmitForm() {
                return !this.formLoading && !!Object.keys(this.updatedData).length
            },
            updatedData() {
                return {
                    ...(this.formData.commission !== this.site.commission
                        ? { commission: this.formData.commission }
                        : {}),
                    ...(this.formData.prix_minimum_filtre !== this.site.prix_minimum_filtre
                        ? { prix_minimum_filtre: this.formData.prix_minimum_filtre }
                        : {}),
                    ...(this.formData.prix_minimum_base !== this.site.prix_minimum_base
                        ? { prix_minimum_base: this.formData.prix_minimum_base }
                        : {}),
                    ...(this.formData.xpath_field !== this.site.xpath_field
                        ? { xpath_field: this.formData.xpath_field }
                        : {}),
                    ...(this.formData.outrepasser_liens !== this.site.outrepasser_liens
                        ? { outrepasser_liens: this.formData.outrepasser_liens }
                        : {}),
                    ...(this.formData.refus_concurrent_mfa !== this.site.refus_concurrent_mfa
                        ? { refus_concurrent_mfa: this.formData.refus_concurrent_mfa }
                        : {}),
                    ...(!this.priceUpdatePending
                        ? {
                              ...(this.formData.site_type !== this.site.site_type
                                  ? { site_type: this.formData.site_type }
                                  : {}),
                              ...(this.formData.group_id !== this.site.group_id
                                  ? { group_id: this.formData.group_id }
                                  : {})
                          }
                        : {})
                }
            },
            tabs() {
                return [
                    {
                        type: 'all',
                        label: `Tous (${this.counts?.total ?? 0})`,
                        icon: 'list',
                        disabled: this.counts.total === 0,
                        index: 0
                    },
                    {
                        type: 'saleable',
                        label: `Vendables (${this.counts?.saleable ?? 0})`,
                        icon: 'check',
                        disabled: this.counts.saleable === 0,
                        index: 1,
                        status: true
                    },
                    {
                        type: 'not_saleable',
                        label: `Invendables (${this.counts?.not_saleable ?? 0})`,
                        icon: 'times',
                        disabled: this.counts.not_saleable === 0,
                        index: 2
                    },
                    {
                        type: 'not_scrapped',
                        label: `Non scrappées (${this.counts.not_scrapped.toLocaleString('fr-FR')})`,
                        icon: 'clock',
                        disabled: this.counts.not_scrapped === 0,
                        index: 3
                    }
                ]
            }
        },
        async created() {
            this.priceUpdatePending = await this.getPriceUpdatePending(this.$route.params.id).catch(() => {})
            this.serpPending = await this.getSerpPending(this.$route.params.id).catch(() => {})
            this.scrapPending = await this.getScrapPending(this.$route.params.id).catch(() => {})

            this.groups = await this.getAll()
            void this.getStatusCounts()

            this.getSite(this.$route.params.id).then(site => {
                this.site = { ...site }
                this.formData = {
                    commission: site.commission,
                    prix_minimum_filtre: site.prix_minimum_filtre,
                    prix_minimum_base: site.prix_minimum_base,
                    xpath_field: site.xpath_field,
                    outrepasser_liens: site.outrepasser_liens,
                    refus_concurrent_mfa: site.refus_concurrent_mfa,
                    site_type: site.site_type,
                    group_id: site.group_id
                }
                this.preloading = false
            })
        },
        methods: {
            ...mapActions('group', ['getAll']),
            ...mapActions('sites', [
                'getSite',
                'getUrlsCountBySite',
                'adminUpdateSite',
                'updateOvercomingLinks',
                'getPriceUpdatePending',
                'getSerpPending',
                'getScrapPending',
                'serp',
                'scrap',
                'reindex'
            ]),
            ...mapActions('urls', ['getStatusCountsBySite']),

            handleSerp() {
                this.serp({ siteId: this.$route.params.id })
                this.serpPending = true
            },

            handleScrap() {
                this.scrap({ siteId: this.$route.params.id })
                this.scrapPending = true
            },

            handleReindex() {
                this.reindex({ siteId: this.$route.params.id })
            },

            async getStatusCounts() {
                this.counts = await this.getStatusCountsBySite({ site_id: this.$route.params.id })
            },

            launchOvercomingLinks() {
                this.formLoading = true
                this.updateOvercomingLinks({
                    site_id: this.site.id,
                    params: { overcoming_links: this.site.outrepasser_liens }
                })
                    .then(() => {
                        this.getStatusCounts()
                        this.forceUpdate = !this.forceUpdate
                        this.$buefy.toast.open({
                            message: 'Site mis à jour',
                            type: 'is-success'
                        })
                    })
                    .finally(() => (this.formLoading = false))
            },
            submitForm() {
                this.adminUpdateSite({ id: this.site.id, ...this.updatedData })
                    .then(site => {
                        this.site = { ...site }
                        this.forceUpdate = !this.forceUpdate
                        this.$buefy.toast.open({
                            message: 'Site mis à jour',
                            type: 'is-success'
                        })
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue',
                            type: 'is-danger'
                        })
                    })
                    .finally(() => {
                        this.formLoading = false
                    })

                if (this.updatedData.group_id || this.updatedData.site_type) {
                    this.priceUpdatePending = true
                }
            },

            editBlackWhiteList(formData) {
                this.formLoading = true

                let form = new FormData()
                form.append('_method', 'PATCH')

                Object.entries(formData).map(([key, value]) => {
                    if (value) {
                        form.append(key, value)
                    }
                })

                // Ajout d'un paramètre pour condition dans le controller
                form.append('scrap_only', '1')

                this.axios
                    .post(`/admin/sites/edit/${this.$route.params.id}`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Site mis à jour',
                            type: 'is-success'
                        })
                        this.site.admin_scrape_blacklist = response.data.site.scrape_blacklist
                        this.site.scrape_blacklist = response.data.site.scrape_blacklist
                        this.site.scrape_sitemap = response.data.site.scrape_sitemap
                        this.site.scrape_whitelist = response.data.site.scrape_whitelist
                        this.site.scrape_whitelist_count =
                            response.data.site.scrape_whitelist_count != null
                                ? response.data.site.scrape_whitelist_count
                                : null
                        this.site.scrape_blacklist_count =
                            response.data.site.scrape_blacklist_count != null
                                ? response.data.site.scrape_blacklist_count
                                : null
                        this.site.admin_scrape_blacklist_count =
                            response.data.site.admin_scrape_blacklist_count != null
                                ? response.data.site.admin_scrape_blacklist_count
                                : null
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.formLoading = false))
            },

            async refreshAll() {
                this.forceUpdate = !this.forceUpdate
                void this.getStatusCounts()
            }
        },
        mounted() {
            this.$echo
                .private(`sites.${this.$route.params.id}`)
                .listen('.SitePriceUpdateFinished', ({ success }) => {
                    this.priceUpdatePending = false
                    if (success) {
                        this.$buefy.toast.open({
                            message: 'Prix mis à jour',
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue lors de la mise à jour du prix',
                            type: 'is-danger'
                        })
                    }
                    this.refreshAll()
                })
                .listen('.SerpFinished', ({ success }) => {
                    this.serpPending = false
                    if (success) {
                        this.$buefy.toast.open({
                            message: 'Récupération des URLs terminée',
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue lors de la récupération des URLs',
                            type: 'is-danger'
                        })
                    }
                    this.refreshAll()
                })
                .listen('.SerpStarted', () => {
                    this.serpPending = true
                })
                .listen('.ScrapFinished', ({ success }) => {
                    this.scrapPending = false
                    if (success) {
                        this.$buefy.toast.open({
                            message: 'Scrap terminé',
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue lors du scrap',
                            type: 'is-danger'
                        })
                    }
                    this.refreshAll()
                })
                .listen('.ScrapStarted', () => {
                    this.scrapPending = true
                })
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .control {
        margin: 0;
    }

    .form-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
        grid-auto-flow: row dense;
        grid-gap: 1.625rem;

        & > * {
            grid-column: span 2;
        }
    }

    .span-md {
        grid-column: span 3;
    }

    .span-lg {
        grid-column: span 4;
    }

    .span-xl {
        grid-column: span 5;
    }

    ::v-deep .switch {
        align-items: center;
        flex: 1 1 auto;
        margin: 0;
    }

    ::v-deep .form-wrapper > .field {
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
    }
</style>
